import React from 'react';
import { Box, Flex, Image, Link, HStack, VStack, Divider, Text } from '@chakra-ui/react';
import Logo from '../assets/logo.png';
import FB from '../assets/bottom_facebook.png';


const Footer = () => {
    return (
        <Flex bg={"gray.100"} p={8} justify={"space-between"} align={"center"} >
            <HStack mb={4} spacing={4}>
                <Image src={Logo} w={"150px"} />
                <Divider orientation="vertical" borderColor="gray.800" borderWidth="1px" h={"100px"} />
                <VStack>
                    <Box>
                        <Text>ARK MEDICAL, LLC</Text>
                        <Text>331 Hickory Street, STE 110</Text>
                        <Text>Fort Collins, CO 80524</Text>
                        <Text>Phone: +1-970-279-3457</Text>
                    </Box>
                </VStack>
            </HStack>
            <VStack>
                <HStack spacing={4}>
                    <VStack justify={"end"}>
                        <Box>
                            <HStack w={"100%"} justify={"end"}>
                                <Link href="#">Home</Link>
                                <Link href="/categories">Products</Link>
                            </HStack>
                            <HStack w={"100%"} justify={"end"}>
                                <Link href="/categories">About Us</Link>
                                <Link href="/categories">Contact</Link>
                            </HStack>
                        </Box>
                    </VStack>
                    <Divider orientation="vertical" borderColor="gray.800" borderWidth="1px" h={"50px"} />
                    <Image mr={4} src={FB} w={"50px"} />
                </HStack>
                <Flex w={"100%"} justify={"flex-end"}>
                    <Text>© ARK MEDICAL, LLC</Text>
                </Flex>
            </VStack>
        </Flex >
    );
};

export default Footer;