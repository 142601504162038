import React, { useState, useEffect } from 'react';
import {
    Box, FormControl, FormLabel, Input, Button, VStack, Textarea, Select,
    SimpleGrid, Image, useToast, useDisclosure, Modal, ModalOverlay, Text,
    ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, ButtonGroup, IconButton, Flex
} from '@chakra-ui/react';
import axios from 'axios';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

function EditProducts() {
    const [products, setProducts] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentProduct, setCurrentProduct] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const toast = useToast();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`https://arkmedcorp.com:3001/api/products?page=${page}&limit=9`);
                console.log("API response:", response.data);
                if (Array.isArray(response.data.products)) {
                    setProducts(response.data.products);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Expected an array of products', response.data);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [page]);

    const goToPrevPage = () => {
        setPage(page => Math.max(page - 1, 1));
    };

    const goToNextPage = () => {
        setPage(page => Math.min(page + 1, totalPages));
    };

    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setCurrentProduct({ ...currentProduct, [e.target.name]: value });
    };

    const handleEditClick = (product) => {
        setCurrentProduct({ ...product });
        onOpen();
    };

    const updateProductList = (updatedProduct) => {
        setProducts(products.map((product) => {
            if (product._id === updatedProduct._id) {
                return updatedProduct;
            }
            return product;
        }));
        onClose();
    };

    const handleDelete = async (productId) => {
        try {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.delete(`https://arkmedcorp.com:3001/api/products/${productId}`, { headers });

            if (response.status === 200) {
                // Remove the product from the local state
                setProducts(products.filter((product) => product._id !== productId));
                console.log('Product deleted successfully');
            } else {
                console.error('Failed to delete product:', response.data);
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleModalSubmit = async (e) => {
        e.preventDefault();

        // Create an updated product object containing only the changed values
        const updatedProduct = {};
        Object.keys(currentProduct).forEach((key) => {
            if (currentProduct[key] !== products.find(p => p._id === currentProduct._id)[key]) {
                updatedProduct[key] = currentProduct[key];
            }
        });

        if (updatedProduct.features === '') {
            delete updatedProduct.features;
        } else if (typeof updatedProduct.features === 'string') {
            updatedProduct.features = updatedProduct.features.split(',').map(s => s.trim());
        }

        if (updatedProduct.availableColors === '') {
            delete updatedProduct.availableColors;
        } else if (typeof updatedProduct.availableColors === 'string') {
            updatedProduct.availableColors = updatedProduct.availableColors.split(',').map(s => s.trim());
        }

        if (Object.keys(updatedProduct).length > 0) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    toast({
                        title: 'Error',
                        description: "No authentication token found.",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                const response = await axios.put(`https://arkmedcorp.com:3001/api/products/${currentProduct._id}`, updatedProduct, { headers });
                updateProductList(response.data);
                toast({
                    title: 'Product Updated',
                    description: "The product's details have been updated.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "There was an error updating the product's details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            toast({
                title: 'No Changes',
                description: "No changes were made to the product's details.",
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
        }

        onClose();
    };

    const renderEditProductModal = () => {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Product</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={handleModalSubmit}>
                        <ModalBody>
                            <VStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Product Image URL</FormLabel>
                                    <Input
                                        type="text"
                                        name="images"
                                        value={currentProduct?.images[0] || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Product Name</FormLabel>
                                    <Input
                                        type="text"
                                        name="productName"
                                        value={currentProduct?.productName || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormControl>
                                <FormControl display="flex" alignItems="center">
                                    <FormLabel htmlFor="isFeatured" mb="0">
                                        Is Featured
                                    </FormLabel>
                                    <Input
                                        type="checkbox"
                                        id="isFeatured"
                                        name="isFeatured"
                                        isChecked={currentProduct?.isFeatured || false}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Product Number</FormLabel>
                                    <Input
                                        type="text"
                                        name="productNumber"
                                        value={currentProduct?.productNumber || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Price</FormLabel>
                                    <Input
                                        type="number"
                                        name="price"
                                        value={currentProduct?.price || ''}
                                        onChange={handleInputChange}

                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        name="description"
                                        value={currentProduct?.description || ''}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Features (comma-separated)</FormLabel>
                                    <Input
                                        type="text"
                                        name="features"
                                        value={currentProduct?.features?.join(',') || ''}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Available Colors (comma-separated)</FormLabel>
                                    <Input
                                        type="text"
                                        name="availableColors"
                                        value={currentProduct?.availableColors?.join(',') || ''}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Stock Status</FormLabel>
                                    <Select
                                        name="stockStatus"
                                        value={currentProduct?.stockStatus || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="In Stock">In Stock</option>
                                        <option value="Out of Stock">Out of Stock</option>
                                    </Select>
                                </FormControl>
                                {/* Add additional inputs for other schema properties */}
                                {/* ... */}
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Save Changes
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        );
    };

    return (
        <Box p={5}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                {products.map((product, index) => (
                    <Box key={product._id} bg="white" p={5} shadow="md" borderWidth="1px" borderRadius="md">
                        <VStack spacing={4} align="stretch">
                            <Box position="relative" height="200px" overflow="hidden" borderRadius="md" bg="gray.100">
                                <Image
                                    src={product.images[0] || 'fallback-image-url'}
                                    alt={`Image of ${product.productName}`}
                                    objectFit="contain"
                                    fallbackSrc="fallback-image-url"
                                    maxH="200px"
                                    maxW="100%"
                                />
                            </Box>
                            <Text fontWeight="bold">{product.productName}</Text>
                            <Text fontSize="sm">${product.price.toFixed(2)}</Text>
                            <Text fontSize="sm" noOfLines={2}>{product.description}</Text>
                            <Text fontSize="sm" color={product.stockStatus === "In Stock" ? "green.500" : "red.500"}>
                                {product.stockStatus}
                            </Text>
                            <Button leftIcon={<EditIcon />} colorScheme="blue" onClick={() => handleEditClick(product)}>
                                Edit
                            </Button>
                            <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => handleDelete(product._id)}>
                                Delete
                            </Button>
                        </VStack>
                    </Box>
                ))}
            </SimpleGrid>
            <Flex w={"100%"} justify={"center"}>
                <ButtonGroup isAttached variant="outline" mt={4}>
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        onClick={goToPrevPage}
                        isDisabled={page === 1}
                    />
                    <Button mr="-px">{`Page ${page} of ${totalPages}`}</Button>
                    <IconButton
                        icon={<ChevronRightIcon />}
                        onClick={goToNextPage}
                        isDisabled={page === totalPages}
                    />
                </ButtonGroup>
            </Flex>
            {currentProduct && renderEditProductModal()}
        </Box>
    );
}

export default EditProducts;