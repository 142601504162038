import React, { useState, useEffect } from 'react';
import {
  Box, Flex, SimpleGrid, Text, Heading, Image, Button, Badge, VStack, Input
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const limit = 9;
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);


  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://arkmedcorp.com:3001/api/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error.response ? error.response.data : error);
    }
  };

  const fetchProducts = async (page = 1) => {
    try {
      const response = await axios.get(`https://arkmedcorp.com:3001/api/products?page=${page}&limit=${limit}`);
      setProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts(currentPage);
  }, [currentPage]);

  const categoryHasProducts = (categoryId) => {
    return products.some(product => product.category && product.category._id.toString() === categoryId);
  };

  // Filter categories based on searchTerm and if they have products
  const filteredCategories = categories
    .filter(category => categoryHasProducts(category._id))
    .filter(category =>
      !searchTerm || category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const filteredProducts = searchTerm
    ? products.filter(product =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (product.category && product.category.name.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    : selectedCategory
      ? products.filter(product => product.category && product.category._id.toString() === selectedCategory)
      : products;

  // Pagination Handlers
  const goToNextPage = () => {
    const newPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(newPage);
    fetchProducts(newPage);
  };

  const goToPreviousPage = () => {
    const newPage = Math.max(currentPage - 1, 1);
    setCurrentPage(newPage);
    fetchProducts(newPage);
  };

  const goToProductDetails = (productId) => {
    navigate(`/products/${productId}`);
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId.toString());
  };

  const resetFilter = () => {
    setSelectedCategory(null);
  };

  return (
    <Flex overflowY={"scroll"} justify={"center"} bg="gray.100" p={5} minH={"100vh"}>
      <Flex w={"90%"} p={4} bg={"white"} shadow={"lg"} borderRadius={"lg"} justify={"space-between"} mb={8}>
        <Flex p={4} justify={"flex-end"} w={"30%"} >
          <VStack p={2} bg={"gray.50"} shadow={"lg"} borderRadius={"lg"} w={"100%"} mr={4}>
            <Heading>Categories</Heading>
            <Input
              placeholder="Search for products or categories..."
              my={4}
              bg={"white"}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SimpleGrid w={"100%"} mt={2} columns={{ base: 2 }} spacing={4}>
              {filteredCategories.map((category) => (
                <Flex
                  key={category._id}
                  flexDirection="column"
                  alignItems="center"
                  p={2}

                  bg={selectedCategory === category._id ? "blue.100" : "white"}
                  borderRadius="lg"
                  shadow="md"
                  cursor="pointer"
                  onClick={() => handleCategoryClick(category._id)}
                >
                  <Text fontSize="md" fontWeight="semibold">{category.name}</Text>
                </Flex>
              ))}
            </SimpleGrid>
            {selectedCategory && (
              <Button mt={4} colorScheme="blue" size={"sm"} onClick={resetFilter}>Reset Filter</Button>
            )}
          </VStack>
        </Flex>
        <Flex p={4} w={"full"} align={"flex-start"} justify={"center"}>
          <VStack p={2} bg={"gray.50"} shadow={"lg"} borderRadius={"lg"} w={"100%"} spacing={8}>
            <Heading>Products {selectedCategory && <Badge ml={2} colorScheme="green">Filtered</Badge>}</Heading>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  <Box
                    minW={"300px"}
                    maxW={"350px"}
                    bg="white"
                    shadow="lg"
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="lg"
                    overflow="hidden"
                    d="flex"
                    flexDirection="column"
                    justifyContent="space-between" // Ensures footer is always at the bottom
                    _hover={{ shadow: "xl", transform: "translateY(-2px)" }} // Hover effect
                    transition="ease-in 0.2s" // Smooth transition for hover effect
                    key={product._id}
                  >
                    <Box p={4}>
                      <Text fontWeight="bold" fontSize="xl" isTruncated>
                        {product.productName}
                      </Text>
                      <Flex align={"center"} justify={"center"} my={4}>
                        {product.images[0] && (
                          <Image boxSize={"200px"} objectFit="contain" src={product.images[0]} alt={product.productName} />
                        )}
                      </Flex>
                      <Text color="gray.600" noOfLines={2}>{product.description}</Text>
                    </Box>
                    <Flex p={4} justify={"flex-end"}>
                      <Button colorScheme="blue" size="sm" onClick={() => goToProductDetails(product._id)}>View Details</Button>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Text>No products found in this category.</Text>
              )}
            </SimpleGrid>
            <Flex mb={4} direction="row" align={"center"} justify="center" mt={4}>
              <Button mr={2} onClick={goToPreviousPage} isDisabled={currentPage <= 1}>Previous</Button>
              <Text mx={2}>Page {currentPage} of {totalPages}</Text>
              <Button colorScheme='blue' ml={2} onClick={goToNextPage} isDisabled={currentPage >= totalPages}>Next</Button>
            </Flex>
          </VStack>
        </Flex>
      </Flex>
    </Flex >
  );
}

export default CategoriesPage;