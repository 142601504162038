import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, Image, Flex, Text, Button, Heading, Badge, HStack, Divider, IconButton, Modal, ModalOverlay, ModalContent, useDisclosure, VStack
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import axios from 'axios';

const ImageCarousel = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleNext = () => {
        setCurrentImage((prev) => (prev + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
    };

    return (
        <Flex bg={"white"} justify={"center"} borderRadius={"lg"} shadow={"lg"} h={"400px"} position="relative">
            <VStack>
                <Image
                    w="400px"
                    h="400px"
                    src={images[currentImage]}
                    alt={`Image ${currentImage + 1}`}
                    borderRadius="lg"
                    objectFit="contain"
                    onClick={onOpen}
                    cursor="pointer"
                />
                <Text>Click image to view more</Text>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay />
                <ModalContent>
                    <Image
                        src={images[currentImage]}
                        alt={`Image ${currentImage + 1}`}
                        maxW="full"
                        maxH="full"
                        objectFit="contain"
                    />
                    <HStack justifyContent="space-between" position="absolute" w="full" h="full" top="0" px={4} alignItems="center">
                        <IconButton icon={<ChevronLeftIcon />} aria-label="Previous image" variant="solid" onClick={handlePrev} />
                        <IconButton icon={<ChevronRightIcon />} aria-label="Next image" variant="solid" onClick={handleNext} />
                    </HStack>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

const ProductPage = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`https://arkmedcorp.com:3001/api/products/${productId}`);
                setProduct(response.data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProduct();
    }, [productId]);

    if (!product) {
        return <Text>Loading...</Text>;
    }

    const handleBack = () => {
        navigate('/products');
    };

    return (
        <Box bg="gray.100" minH="100vh" p={5}>
            <Flex direction="column" align="center" my={6}>
                <Flex direction="column" maxW="5xl" mx="auto" bg="white" shadow="xl" borderRadius="lg" overflow="hidden">
                    <Button colorScheme="blue" onClick={handleBack}>Back to Products</Button>
                    <ImageCarousel images={product.images} />
                    <Box p={6}>
                        <Heading color={"blue.500"} mb={4}>{product.productName}</Heading>
                        <Text fontSize="xl" fontWeight="semibold" mb={2}>${product.price}</Text>
                        <Text mb={4}>P/N: {product.productNumber}</Text>
                        <Text mb={4}>{product.description}</Text>
                        <Text mb={4}>{product.stockStatus}</Text>
                        <Divider my={4} />
                        <Flex wrap="wrap">
                            {product.features.map((feature, index) => (
                                <Badge key={index} mr={2} mb={2} colorScheme="blue">{feature}</Badge>
                            ))}
                        </Flex>
                        <Divider my={4} />
                        <Flex wrap="wrap">
                            {product.availableColors.map((color, index) => (
                                <Badge key={index} mr={2} mb={2} colorScheme="gray">{color}</Badge>
                            ))}
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
};

export default ProductPage;