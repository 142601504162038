import React, { useState } from 'react';
import axios from 'axios';
import { Box, FormControl, FormLabel, Input, Button, useToast, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: ''
  });
  const toast = useToast();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setLoginInfo({
      ...loginInfo,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://arkmedcorp.com:3001/api/login', loginInfo);
      console.log(response.data);

      localStorage.setItem('token', response.data.token);
      window.dispatchEvent(new Event('storage'));

      toast({
        title: "Login successful",
        description: "You have successfully logged in.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate('/edit-products');

    } catch (error) {
      console.error(error.response.data);
      toast({
        title: "Login failed",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH={'75vh'} w={"100%"} justify={"center"}>
      <Box w={"500px"} my={8} textAlign="left">
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" onChange={handleInputChange} value={loginInfo.email} required />
          </FormControl>
          <FormControl mt={6}>
            <FormLabel>Password</FormLabel>
            <Input type="password" name="password" onChange={handleInputChange} value={loginInfo.password} required />
          </FormControl>
          <Button width="full" mt={4} type="submit">
            Login
          </Button>
        </form>
      </Box>
    </Flex>
  );
}

export default LoginPage;
