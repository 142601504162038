import React, { useState, useEffect } from 'react';
import {
    Box, FormControl, FormLabel, Input, Button, Textarea, Checkbox, Select, useToast, IconButton, HStack, Flex, Image
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';

function AddProduct() {
    const [productData, setProductData] = useState({
        productName: '',
        productNumber: '',
        price: '',
        description: '',
        features: '',
        isFeatured: false,
        availableColors: '',
        options: '',
        carryingOptions: '',
        stockStatus: '',
        images: '',
        category: '',
        specs: {
            weight: '',
            material: '',
        },
        dimensions: {
            length: '',
            width: '',
            height: '',
        },
    });

    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [imageFiles, setImageFiles] = useState(null);
    const [imagePreviews, setImagePreviews] = useState([]);

    const toast = useToast();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://arkmedcorp.com:3001/api/categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setImageFiles(files);

        // Create image previews
        const newImagePreviews = files.map(file => URL.createObjectURL(file));
        setImagePreviews(newImagePreviews);
    };

    const removeImage = (index) => {
        setImageFiles(imageFiles.filter((_, i) => i !== index));
        setImagePreviews(imagePreviews.filter((_, i) => i !== index));
    };

    const handleInputChange = (e) => {
        if (e.target.name === 'isFeatured') {
            setProductData({
                ...productData,
                [e.target.name]: e.target.checked
            });
        } else {
            setProductData({
                ...productData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleCategoryChange = (e) => {
        const categoryName = e.target.value;
        setProductData({ ...productData, category: categoryName });

        const category = categories.find(cat => cat.name === categoryName);
        if (category) {
            setSelectedCategoryId(category._id);
        } else {
            setSelectedCategoryId(''); // Reset or handle new category creation
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let categoryId = selectedCategoryId;

        if (!categoryId) {
            const existingCategory = categories.find(cat => cat.name.toLowerCase() === productData.category.toLowerCase());

            if (!existingCategory) {
                try {
                    const response = await axios.post('https://arkmedcorp.com:3001/api/categories', { name: productData.category });
                    categoryId = response.data._id;
                } catch (error) {
                    console.error('Error creating new category:', error);
                    toast({
                        title: 'Error creating category.',
                        description: 'Failed to create a new category.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                    return;
                }
            } else {
                categoryId = existingCategory._id;
            }
        }

        const token = localStorage.getItem('token');
        if (!token) {
            toast({
                title: 'Error',
                description: 'No authentication token found.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const formData = new FormData();
        imageFiles.forEach((file) => {
            formData.append('images', file); // 'images' should match the field name expected by Multer
        });

        formData.append('productName', productData.productName);
        formData.append('productNumber', productData.productNumber);
        formData.append('price', productData.price);
        formData.append('description', productData.description);

        // Correctly handle the features and carryingOptions
        if (productData.features) {
            productData.features.split(',').forEach(feature => {
                formData.append('features', feature.trim()); // No JSON.stringify
            });
        }

        if (productData.carryingOptions) {
            productData.carryingOptions.split(',').forEach(option => {
                formData.append('carryingOptions', option.trim()); // No JSON.stringify
            });
        }

        // Before appending them to formData, split them by commas and trim any whitespace
        if (productData.availableColors) {
            productData.availableColors.split(',').forEach(color => {
                formData.append('availableColors', color.trim());
            });
        }

        if (productData.options) {
            productData.options.split(',').forEach(option => {
                formData.append('options', option.trim());
            });
        }

        formData.append('isFeatured', productData.isFeatured);
        formData.append('stockStatus', productData.stockStatus);
        formData.append('specs', JSON.stringify(productData.specs));
        formData.append('dimensions', JSON.stringify(productData.dimensions));
        formData.append('category', categoryId);

        try {
            await axios.post('https://arkmedcorp.com:3001/api/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
            toast({
                title: 'Product added.',
                description: 'The product has been added successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            setProductData({
                productName: '',
                productNumber: '',
                price: '',
                description: '',
                features: '',
                isFeatured: false,
                availableColors: '',
                options: '',
                carryingOptions: '',
                stockStatus: 'Select stock status',
                images: '',
                category: '',
                specs: {
                    weight: '',
                    material: '',
                },
                dimensions: {
                    length: '',
                    width: '',
                    height: '',
                },
            });
            setSelectedCategoryId('');
            setImageFiles(null);
            setImagePreviews([]);

        } catch (error) {
            console.error('Error adding product:', error.response?.data || error.message);
            toast({
                title: 'Error adding product.',
                description: error.response?.data.message || 'Failed to add the product.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSpecsChange = (e) => {
        setProductData({
            ...productData,
            specs: { ...productData.specs, [e.target.name]: e.target.value }
        });
    };

    const handleDimensionsChange = (e) => {
        setProductData({
            ...productData,
            dimensions: { ...productData.dimensions, [e.target.name]: e.target.value }
        });
    };

    return (
        <Flex bg="gray.100" p={5} w={"100%"} justify={"center"}>
            <Box w={"50%"} borderRadius={"lg"} bg="blackAlpha.200" p={5}>
                <form onSubmit={handleSubmit}>
                    <HStack w={"100%"} justify={"space-evenly"}>
                        <FormControl mr={4} mt={4} isRequired>
                            <FormLabel>Product Name</FormLabel>
                            <Input bg={"white"} name="productName" onChange={handleInputChange} value={productData.productName} />
                        </FormControl>

                        <FormControl mr={4} mt={4} isRequired>
                            <FormLabel>Product Number</FormLabel>
                            <Input bg={"white"} name="productNumber" onChange={handleInputChange} value={productData.productNumber} />
                        </FormControl>

                        <FormControl mt={4} isRequired>
                            <FormLabel>Price</FormLabel>
                            <Input bg={"white"} name="price" type="number" onChange={handleInputChange} value={productData.price} />
                        </FormControl>
                    </HStack>

                    <FormControl mt={4} isRequired>
                        <FormLabel>Description</FormLabel>
                        <Textarea bg={"white"} name="description" onChange={handleInputChange} value={productData.description} />
                    </FormControl>

                    <HStack w={"100%"} justify={"space-evenly"}>
                        <FormControl mt={4} mr={4} isRequired>
                            <FormLabel>Category</FormLabel>
                            <Input
                                name="category"
                                bg={"white"}
                                onChange={handleCategoryChange}
                                value={productData.category}
                                list="category-list"
                            />
                            <datalist id="category-list">
                                {categories.map(category => (
                                    <option key={category._id} value={category.name} />
                                ))}
                            </datalist>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Features</FormLabel>
                            <Input bg={"white"} name="features" onChange={handleInputChange} value={productData.features} />
                        </FormControl>
                    </HStack>
                    <HStack w={"100%"} justify={"space-evenly"}>
                        <FormControl mt={4} mr={4}>
                            <FormLabel>Available Colors</FormLabel>
                            <Input bg={"white"} name="availableColors" onChange={handleInputChange} value={productData.availableColors} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Options</FormLabel>
                            <Input bg={"white"} name="options" onChange={handleInputChange} value={productData.options} />
                        </FormControl>

                    </HStack>

                    <FormControl mt={4}>
                        <FormLabel>Specs</FormLabel>
                        <HStack w={"100%"} justify={"space-evenly"}>
                            <Input
                                name="weight"
                                mb={1}
                                mr={4}
                                bg={"white"}
                                placeholder="Weight"
                                onChange={handleSpecsChange}
                                value={productData.specs.weight}
                            />
                            <Input
                                name="material"
                                mb={1}
                                bg={"white"}
                                placeholder="Material"
                                onChange={handleSpecsChange}
                                value={productData.specs.material}
                            />
                        </HStack>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>Dimensions</FormLabel>
                        <HStack w={"100%"} justify={"space-evenly"}>
                            <Input
                                name="length"
                                bg={"white"}
                                mr={4}
                                mb={1}
                                placeholder="Length"
                                onChange={handleDimensionsChange}
                                value={productData.dimensions.length}
                            />
                            <Input
                                name="width"
                                bg={"white"}
                                mb={1}
                                mr={4}
                                ml={4}
                                placeholder="Width"
                                onChange={handleDimensionsChange}
                                value={productData.dimensions.width}
                            />
                            <Input
                                name="height"
                                bg={"white"}
                                mb={1}
                                ml={4}
                                placeholder="Height"
                                onChange={handleDimensionsChange}
                                value={productData.dimensions.height}
                            />
                        </HStack>
                    </FormControl>

                    <HStack w={"100%"} justify={"space-evenly"}>
                        <FormControl mt={4}>
                            <FormLabel>Carrying Options</FormLabel>
                            <Input bg={"white"} name="carryingOptions" onChange={handleInputChange} value={productData.carryingOptions} />
                        </FormControl>

                        <FormControl mt={4} isRequired>
                            <FormLabel>Stock Status</FormLabel>
                            <Select bg={"white"} name="stockStatus" onChange={handleInputChange} placeholder="Select stock status">
                                <option value="In Stock">In Stock</option>
                                <option value="Out of Stock">Out of Stock</option>
                            </Select>
                        </FormControl>
                    </HStack>

                    <FormControl mt={4}>
                        <FormLabel>Images</FormLabel>
                        <Box>
                            <Input
                                type="file"
                                bg={"white"}
                                name="images"
                                onChange={handleFileChange}
                                multiple
                                hidden // Hide the default input
                                id="image-upload"
                            />
                            <Button as="label" htmlFor="image-upload" colorScheme="blue">Upload Images</Button>
                        </Box>
                        <Box mt={2}>
                            {imagePreviews.map((preview, index) => (
                                <Box key={index} display="inline-block" position="relative" mr={2} mt={2}>
                                    <Image src={preview} alt={`image-preview-${index}`} boxSize="100px" objectFit="cover" borderRadius="md" />
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        size="sm"
                                        colorScheme="red"
                                        position="absolute"
                                        top={-2}
                                        right={-2}
                                        onClick={() => removeImage(index)}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </FormControl>

                    <HStack>
                        <FormControl mt={4}>
                            <FormLabel>Is Featured</FormLabel>
                            <Checkbox bg={"white"} name="isFeatured" onChange={handleInputChange} isChecked={productData.isFeatured} />
                        </FormControl>

                        <Button mt={4} colorScheme="blue" type="submit">Add Product</Button>
                    </HStack>
                </form>
            </Box>
        </Flex >
    );
}

export default AddProduct;
