import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
    Box, Flex, Image, Text, Heading, VStack, HStack, SimpleGrid, Button, Input, useBreakpointValue
} from '@chakra-ui/react';
import med from '../assets/med.jpg';
import flag from '../assets/flag.png';

function Home() {
    const bgPosition = useBreakpointValue({ base: "top", md: "center" });
    const [isFeatured, setIsFeatured] = useState(false);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchFeaturedProducts = async () => {
            try {
                const response = await axios.get('https://arkmedcorp.com:3001/api/products/featured');
                setFeaturedProducts(response.data);
                setIsFeatured(response.data.length > 0);
            } catch (error) {
                console.error('Error fetching featured products:', error);
            }
        };

        fetchFeaturedProducts();
    }, []);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const response = await axios.post('https://arkmedcorp.com:3001/api/emails', { email });
            console.log(response.data.message);
            // Handle success - clear input, show message, etc.
        } catch (error) {
            console.error(error.response.data.message);
            // Handle error - show error message, etc.
        }
        setIsSubmitting(false);
    };

    return (
        <Box minH="100vh" p={5} bg="gray.100">

            {/* Hero Section */}
            <VStack
                bg="gray.100"
                p={{ base: 4, md: 8 }}
                bgImage={`url(${med})`}
                bgPos={bgPosition}
                bgSize="cover"
                bgRepeat="no-repeat"
                borderRadius="lg"
                shadow="lg"
                mb={10}
                h="700px"
                spacing={4}
                justify="center"
                align="center"
            >
                <Box
                    h="300px"
                    w={{ base: "90%", md: "80%", lg: "2xl" }}
                    bg="whiteAlpha.900"
                    borderRadius="lg"
                    shadow="dark-lg"
                    p={8}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Heading as="h2" size="2xl" fontWeight="bold" textAlign="center">
                        Discover Military Medical Supplies at Ark Medical
                    </Heading>
                    <Text fontSize="lg" color="gray.600" my={4} textAlign="center">
                        We offer a wide range of high-quality military medical supplies and products to meet your needs.
                    </Text>
                    <HStack spacing={4} w="full" maxW="md" justifyContent="center">
                        <Input
                            flex="1"
                            bg="white"
                            placeholder="Enter your email"
                            size="md"
                            value={email}
                            onChange={handleEmailChange}
                            isDisabled={isSubmitting}
                        />
                        <Button colorScheme="blue" size="md" onClick={handleSubmit} isLoading={isSubmitting}>
                            Sign Up
                        </Button>
                    </HStack>
                </Box>
            </VStack>

            {/* Features / Categories Section */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mb={10}>
                {/* Feature 1 */}
                <Box bg="white" p={5} shadow="md" borderRadius="md" textAlign="center">
                    <Image src={flag} h="50px" mb={3} />
                    <Heading size="md" mb={2}>Discover How Our Products Work</Heading>
                    <Text fontSize="sm" mb={4}>Our military medical supplies and products are designed to meet the highest standards.</Text>
                    <Button variant="outline" size="sm">Learn More</Button>
                </Box>
                {/* Feature 2 */}
                <Box bg="white" p={5} shadow="md" borderRadius="md" textAlign="center">
                    <Image src={flag} h="50px" mb={3} />
                    <Heading size="md" mb={2}>Experience the Quality of Our Products</Heading>
                    <Text fontSize="sm" mb={4}>Our military medical supplies are reliable and have been thoroughly tested.</Text>
                    <Button variant="outline" size="sm">Learn More</Button>
                </Box>
                {/* Feature 3 */}
                <Box bg="white" p={5} shadow="md" borderRadius="md" textAlign="center">
                    <Image src={flag} h="50px" mb={3} />
                    <Heading size="md" mb={2}>Find the Right Products for Your Needs</Heading>
                    <Text fontSize="sm" mb={4}>We provide a range of products to fit every possible medical scenario in the field.</Text>
                    <Button variant="outline" size="sm">Learn More</Button>
                </Box>
            </SimpleGrid>

            {isFeatured && (
                <Box bg="gray.100" p={5}>
                    <Heading as="h2" size="xl" textAlign="center" mb={6}>
                        Featured Products
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={10} justifyContent="center">
                        {featuredProducts.map((product) => (
                            <Box
                                key={product._id}
                                bg="white"
                                shadow="xl"
                                borderRadius="lg"
                                overflow="hidden"
                                d="flex"
                                flexDir="column"
                                transition="transform 0.2s, box-shadow 0.2s"
                                _hover={{ transform: 'scale(1.05)', shadow: '2xl' }}
                                h="100%"
                            >
                                <Flex align="center" justify="center" h="160px" bg="white">
                                    <Image
                                        src={product.images[0]}
                                        maxH="100%"
                                        maxW="100%"
                                        objectFit="contain"
                                        alt={product.productName}
                                    />
                                </Flex>
                                <Box p={4} flex="1" d="flex" flexDir="column" justifyContent="space-between">
                                    <VStack spacing={3}>
                                        <Text
                                            fontWeight="bold"
                                            textAlign="center"
                                            isTruncated
                                            noOfLines={1}
                                            fontSize={{ base: "md", md: "lg" }}
                                        >
                                            {product.productName}
                                        </Text>
                                        <Text
                                            color="gray.600"
                                            textAlign="center"
                                            fontSize="sm"
                                            noOfLines={{ base: 3, md: 2 }}
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                        >
                                            {product.description}
                                        </Text>
                                    </VStack>
                                    <Button
                                        colorScheme="blue"
                                        size="sm"
                                        mt={4}
                                        w="full"
                                        onClick={() => navigate(`/products/${product._id}`)}
                                    >
                                        View Product
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>

                </Box>
            )}

            {/* Informational Section */}
            <Box bg="white" p={{ base: 4, md: 8 }} shadow="md" borderRadius="md" mb={10}>
                <VStack align="start" spacing={4}>
                    <Heading as="h3" size="lg" fontWeight="bold">
                        Enhance your medical capabilities with our products
                    </Heading>
                    <Text fontSize="md" color="gray.700">
                        As dedicated providers of military medical supplies and products, we're here to meet the needs of practitioners. Our high-quality products are tailored to the rigorous standards and provide reliable support in critical situations.
                    </Text>
                    {/* Optional: Add a CTA button if the wireframe requires it */}
                    <Button colorScheme="blue" size="md" mt={4}>Learn More</Button>
                </VStack>
            </Box>
        </Box >
    );
}

export default Home;
