import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import CategoriesPage from './Pages/CategoriesPage';
import ProductPage from './Pages/ProductPage';
import SignupPage from './Pages/SignUp';
import LoginPage from './Pages/Login';
import Header from './Components/Header';
import AddProduct from './Pages/AddProduct';
import EditProducts from './Pages/EditProducts';
import Home from './Pages/Home';
import Footer from './Components/Footer';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<CategoriesPage />} />
          <Route path="/products/:productId" element={<ProductPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/edit-products" element={<EditProducts />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;