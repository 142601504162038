import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Flex, HStack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignupPage() {
    const [signupInfo, setSignupInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'admin'
    });

    const toast = useToast();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setSignupInfo({
            ...signupInfo,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (signupInfo.password !== signupInfo.confirmPassword) {
            toast({
                title: 'Error',
                description: 'Passwords do not match',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            await axios.post('https://arkmedcorp.com:3001/api/signup', signupInfo);
            toast({
                title: 'Account created',
                description: 'You have successfully signed up!',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            navigate('/'); // Redirect to home page after successful signup
        } catch (error) {
            toast({
                title: 'Error',
                description: error.response?.data || 'Failed to sign up',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box bg="gray.100" w={"100%"} minH="100vh" p={5}>
            <Flex mt={4} w={"100%"} justify={"center"}>
                <Flex mt={4} p={4} bg={"white"} borderRadius={"2xl"} justify={"center"} w={"50%"}>
                    <form onSubmit={handleSubmit}>
                        <HStack w={"100%"}>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input type="text" name="firstName" onChange={handleInputChange} value={signupInfo.firstName} required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input type="text" name="lastName" onChange={handleInputChange} value={signupInfo.lastName} required />
                            </FormControl>
                        </HStack>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" name="email" onChange={handleInputChange} value={signupInfo.email} required />
                        </FormControl>
                        <FormControl mt={6}>
                            <FormLabel>Password</FormLabel>
                            <Input type="password" name="password" onChange={handleInputChange} value={signupInfo.password} required />
                        </FormControl>
                        <FormControl mt={6}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input type="password" name="confirmPassword" onChange={handleInputChange} value={signupInfo.confirmPassword} required />
                        </FormControl>
                        <Button colorScheme="blue" width="full" mt={4} type="submit">
                            Sign Up
                        </Button>
                    </form>
                </Flex>
            </Flex>
        </Box>
    );
}

export default SignupPage;