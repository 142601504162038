import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Flex, Image, Link, HStack } from '@chakra-ui/react';
import logo from '../assets/logo.png';
import headerBackground from '../assets/HeaderBackground.png';

const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token); // Use jwtDecode here
                const currentDate = new Date();

                if (decodedToken.exp * 1000 < currentDate.getTime()) {
                    console.log('Token expired.');
                } else {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                // Handle error for an invalid token
                console.error('Invalid token:', error);
            }
        }
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token); // Use jwtDecode here
                    const currentDate = new Date();

                    if (decodedToken.exp * 1000 < currentDate.getTime()) {
                        console.log('Token expired.');
                        setIsLoggedIn(false);
                    } else {
                        setIsLoggedIn(true);
                    }
                } catch (error) {
                    console.error('Invalid token:', error);
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
        };

        // Attach the event listener for local storage changes
        window.addEventListener('storage', handleStorageChange);

        // Call the handler function to set initial state
        handleStorageChange();

        // Remove event listener on cleanup
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);


    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    return (
        <Flex
            justify={"center"}
            px={5}
            py={3}
            bgImage={`url(${headerBackground})`}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            h={"150px"}
        >
            {/* Header */}
            <Flex
                w={"50%"}
                bg="white"
                borderRadius={"lg"}
                p={3}
                h={"80px"}
                shadow={"dark-lg"}
                justify="space-between"
                align="center"
            >
                <Link href="/home">
                    <Image src={logo} h="60px" cursor="pointer" />
                </Link>
                <HStack mr={4} spacing={8}>
                    <Link href="/about-us">About Us</Link>
                    <Link href="/products">Products</Link>
                    {isLoggedIn ? (
                        <>
                            <Link href="/add-product">Add Product</Link>
                            <Link href="/edit-products">Edit Products</Link>
                            <Link href="/signup">Add User</Link>
                            <Link href="/logout" onClick={handleLogout}>Log Out</Link>
                        </>
                    ) : (
                        <>
                            <Link href="/login">Login</Link>
                        </>
                    )}
                </HStack>
            </Flex>
        </Flex>
    );
};

export default Header;
